import React from "react"
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './main.scss';
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image";
import SEO from "../components/seo";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle} from "mdbreact";
import {Helmet} from "react-helmet";
import MenuContainer from "../components/menu-container";

const IndexPage = () => (
    <StaticQuery
    query={graphql`
        query {
            desktop: file(relativePath: { eq: "haarsalon.jpg" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1110) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `}
    render={data => {
        const imageData = data.desktop.childImageSharp.fluid;
        
        return (
            <div>
                <SEO title={'Michelle\'s Haarsalon'} />
                <MenuContainer />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="landing-image-container">
                                <Img className="landing-image" fluid={imageData} alt="" />
                                <MDBBtn
                                    className="landing-image-button"
                                    href="https://widget.salonized.com/widget/?color=%23ff8700&language=nl&company=tfVxiz34APmPPyEc5Zbh8mZW"
                                    target="_blank"
                                    color="primary">Afspraak maken</MDBBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid border-top border-primary">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <MDBCard className="mt-5">
                                <MDBCardBody className="border-bottom border-primary">
                                    <MDBCardTitle className="tenby-five">Welkom op de website van Michelle’s Haarsalon!</MDBCardTitle>
                                    <MDBCardText>
                                        Een gezellige kapsalon waar persoonlijke aandacht voor onze klanten en kwaliteit voor haar en hoofdhuid voorop staat.
                    
                                        Onze enthousiaste haarstylisten kijken samen naar je wensen en de mogelijkheden voor een coupe, kleur en de juiste stylings- en verzorgingsproducten.
                                        Daarna is het tijd voor ontspanning, laat je lekker in de watten leggen en geniet!
                                    </MDBCardText>
                                    <MDBBtn href="https://widget.salonized.com/widget/?color=%23ff8700&language=nl&company=tfVxiz34APmPPyEc5Zbh8mZW" color="primary">Afspraak maken</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
        
                            <MDBCard className="mt-5 mb-5">
                                <MDBCardBody className="border-bottom border-primary">
                                    <MDBCardTitle className="tenby-five">Onze reviews</MDBCardTitle>
                
                                    <Helmet>
                                        <script src="https://cdn.salonized.com/widget.js" data-name="salonized" data-microsite-url="https://michelles-haarsalon-1.salonized.com"></script>
                
                                    </Helmet>
                                    <div className="salonized-reviews-mini" data-link='https://michelles-haarsalon-1.salonized.com/reviews'></div>
            
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <MDBCard className="mt-5 mb-5">
                                <MDBCardBody className="border-bottom border-primary">
                                
                                    <Helmet>
                                        <script src="https://static-widget.salonized.com/loader.js"></script>
                                    </Helmet>
                                    <div className="salonized-booking" data-company="tfVxiz34APmPPyEc5Zbh8mZW" data-color="#ff8700" data-language="nl" data-size="normal" data-position="right"></div>                               

                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </div>
            </div>
        )
    }}
    />
);

export default IndexPage
